import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClassListingHeroSection from "../class-details/ClassListingHeroSection";
import Navbar from "../Navbar";
import Footer from "../Footer";
import adv1 from "../../assets/adv-1.svg";
import adv2 from "../../assets/adv-2.svg";
import adv3 from "../../assets/adv-3.svg";
import adv4 from "../../assets/adv-4.svg";
import bg from "../../assets/kids-finance.jpeg";
import bg1 from "../../assets/Ethics.jpg";
import bg2 from "../../assets/blogging.jpg";
import UpcomingSection from "./upcomingSection";

export default function UpcomingCourseDetails() {
  const { t } = useTranslation();
  const { id } = useParams();

  const courses = [
    {
      id: 1,
      title: t("upcomingCoursesSection.courses.ethics.title"),
      description: t("upcomingCoursesSection.courses.ethics.description"),
      borderColor: "border-blue-500",
      icon: "⚖️",
      time: t("upcomingCoursesSection.courses.ethics.time"),
      image: bg1,
    },
    {
      id: 2,
      title: t("upcomingCoursesSection.courses.blogging.title"),
      description: t("upcomingCoursesSection.courses.blogging.description"),
      borderColor: "border-green-500",
      icon: "✍️",
      time: t("upcomingCoursesSection.courses.blogging.time"),
      image: bg2,
    },
    {
      id: 3,
      title: t("upcomingCoursesSection.courses.entrepreneurship.title"),
      description: t(
        "upcomingCoursesSection.courses.entrepreneurship.description"
      ),
      borderColor: "border-teal-500",
      icon: "💼",
      time: t("upcomingCoursesSection.courses.entrepreneurship.time"),
      image: bg,
    },
  ];

  const course = courses.find((course) => course.id === parseInt(id));

  if (!course) {
    return <div className="text-red-500">Course not found</div>;
  }

  // Tags for the hero section
  const tags = [
    t("tagsforart1", { ns: "classes" }), // Translated tag with namespace
    t("tagsforart2", { ns: "classes" }), // Example for dynamic age range
  ];

  const advantages = [
    {
      id: 1,
      name: t("advantages1", { ns: "classes" }),
      icon: adv1,
    },
    {
      id: 2,
      name: t("advantages2", { ns: "classes" }),
      icon: adv2,
    },
    {
      id: 3,
      name: t("advantages3", { ns: "classes" }),
      icon: adv3,
    },
    {
      id: 4,
      name: t("advantages4", { ns: "classes" }),
      icon: adv4,
    },
  ];

  return (
    <>
      <Navbar />
      <div className="pb-10">
        <ClassListingHeroSection
          title={course.title}
          description={course.description}
          reviews={t("codingClasses.reviews")}
          tags={tags} // Pass tags to hero section
          advantages={advantages}
          background={course.image}
        />
      </div>
      <UpcomingSection />
      <Footer />
    </>
  );
}
