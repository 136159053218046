import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const CustomPackageCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-8 px-5 mt-6 shadow-md rounded-lg text-center flex flex-col gap-2 items-center justify-center">
      <div className="flex justify-center items-center">
        <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4">
          {t("pricing.customOffer")}
        </h2>
      </div>
      <p className="text-gray-600 sm:text-base text-sm">
        {t("pricing.description")}
      </p>
      <Link
        to="/contact-us"
        className="bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-lg mt-2"
      >
        {t("pricing.contactUs")}
      </Link>
    </div>
  );
};

// type Plan = {
//   name: string;
//   classes: number;
//   price: string;
//   pricePerHour: string;
//   details: string[];
//   isPopular?: boolean;
// };

// const plans: Plan[] = [
//   {
//     name: "pricing.plans.lesson8.name", // Translation key for 8 Lessons
//     classes: 8,
//     price: "pricing.plans.lesson8.price", // Translation key for price
//     pricePerHour: "pricing.plans.lesson8.pricePerHour", // Translation key for price per hour
//     details: [
//       "pricing.plans.lesson8.details.1", // Translation key for details
//       "pricing.plans.lesson8.details.2",
//       "pricing.plans.lesson8.details.3",
//       "pricing.plans.lesson8.details.4",
//     ],
//     isPopular: false,
//   },
//   {
//     name: "pricing.plans.lesson12.name",
//     classes: 12,
//     price: "pricing.plans.lesson12.price",
//     pricePerHour: "pricing.plans.lesson12.pricePerHour",
//     details: [
//       "pricing.plans.lesson12.details.1",
//       "pricing.plans.lesson12.details.2",
//       "pricing.plans.lesson12.details.3",
//       "pricing.plans.lesson12.details.4",
//     ],
//     isPopular: true,
//   },
//   {
//     name: "pricing.plans.lesson16.name",
//     classes: 16,
//     price: "pricing.plans.lesson16.price",
//     pricePerHour: "pricing.plans.lesson16.pricePerHour",
//     details: [
//       "pricing.plans.lesson16.details.1",
//       "pricing.plans.lesson16.details.2",
//       "pricing.plans.lesson16.details.3",
//       "pricing.plans.lesson16.details.4",
//     ],
//   },
// ];

function PricingPlans() {
  const { t } = useTranslation();
  // const [selectedPlan, setSelectedPlan] = useState<string>(plans[0].name);

  const plans = [
    {
      name: "pricing.plans.lesson8.name", // Translation key for 8 Lessons
      classes: 8,
      price: "pricing.plans.lesson8.price", // Translation key for price
      discount: 20,
      originalPrice: 200,
      discountedPrice: 160,
      pricePerHour: "pricing.plans.lesson8.pricePerHour", // Translation key for price per hour
      details: [
        "pricing.plans.lesson8.details.1", // Translation key for details
        "pricing.plans.lesson8.details.2",
        "pricing.plans.lesson8.details.3",
        "pricing.plans.lesson8.details.4",
        "pricing.plans.lesson8.details.5",
      ],
      isPopular: false,
      buttonColor: "bg-orange-500",
      gradient: "from-teal-400 to-teal-600",
    },
    {
      name: "pricing.plans.lesson12.name", // Translation key for 12 Lessons
      classes: 12,
      originalPrice: 400,
      discount: 30,
      discountedPrice: 280,
      price: "pricing.plans.lesson12.price",
      pricePerHour: "pricing.plans.lesson12.pricePerHour",
      details: [
        "pricing.plans.lesson12.details.1",
        "pricing.plans.lesson12.details.2",
        "pricing.plans.lesson12.details.3",
        "pricing.plans.lesson12.details.4",
        "pricing.plans.lesson12.details.5",
      ],
      isPopular: false,
      buttonColor: "bg-green-500",
      gradient: "from-purple-400 to-purple-600",
    },
    {
      name: "pricing.plans.lesson16.name", // Translation key for 16 Lessons
      classes: 16,
      originalPrice: 600,
      discount: 40,
      discountedPrice: 360,
      price: "pricing.plans.lesson16.price",
      pricePerHour: "pricing.plans.lesson16.pricePerHour",
      details: [
        "pricing.plans.lesson16.details.1",
        "pricing.plans.lesson16.details.2",
        "pricing.plans.lesson16.details.3",
        "pricing.plans.lesson16.details.4",
        "pricing.plans.lesson16.details.5",
      ],
      isPopular: true,
      buttonColor: "bg-blue-500",
      gradient: "from-pink-400 to-pink-600",
    },
  ];

  return (
    <>
      <Navbar />
      <div className="py-6 bg-gray-100">
        <div className="container max-w-[1200px] xl:px-0 px-5  mx-auto pt-10 pb-20">
          <h2 className="text-center text-2xl sm:text-3xl font-bold mb-4">
            {t("pricing.title")}
          </h2>
          <p className="text-center mb-12">{t("pricing.description")}</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 xl:gap-8">
            {plans.map((plan, index) => (
              <div
                key={index}
                className={`relative z-0 bg-gradient-to-bl ${
                  plan.gradient
                } text-white rounded-2xl shadow-lg p-5 sm:p-10 transform transition duration-300 min-h-[500px] ${
                  plan.isPopular ? "border-4 border-yellow-500 scale-105" : ""
                }`}
              >
                {plan.isPopular && (
                  <div className="absolute top-0 -mt-7 bg-yellow-500 text-black rounded-tl-lg rounded-tr-lg px-4 py-1 text-sm font-bold left-2/4 -translate-x-2/4">
                    {t("pricing.popular")}
                  </div>
                )}

                <h1 className="text-2xl font-semibold uppercase">
                  {t(plan.name)}
                </h1>
                <div className="my-3 flex flex-col gap-1 py-5 relative z-0 after:w-10/12 after:h-full after:bg-white after:absolute after:top-0 after:left-[-80px] xl:after:left-[-40px] after:z-[-1] text-black after:rounded-r-full">
                  <p className="text-2xl opacity-70">
                    <span className="line-through">{plan.originalPrice}$</span>
                    <span className="text-base">/mo</span>
                    <span
                      className={`bg-red-500 text-white py-1 px-2 rounded-full text-sm bg-gradient-to-bl ml-3 ${plan.gradient}`}
                    >
                      {plan.discount} %
                    </span>
                  </p>
                  <h3 className="text-4xl font-semibold">
                    {plan.discountedPrice}$
                    <span className="text-base">/mo</span>
                  </h3>
                </div>
                <ul className="mb-6">
                  {plan.details.map((feature, idx) => (
                    <li key={idx} className="flex items-center gap-2 mb-2">
                      {t(feature)}
                    </li>
                  ))}
                </ul>
                <a
                  href="https://scheduler.zoom.us/darstop/free-trial-lesson"
                  target="_blank"
                  className="w-full text-white font-semibold py-4 px-6 shadow-lg hover:bg-main hover:text-white transition-all duration-300 flex items-center justify-center border-2 border-white rounded-full"
                >
                  {t("pricing.trialLesson")}
                </a>

                {/* {plan.isPopular && (
                  <div className="absolute top-5 right-5 bg-white text-black font-bold w-20 h-20 rounded-full flex items-center justify-center text-center flex-col text-base shadow-lg">
                    {t("pricing.popular")}
                  </div>
                )} */}
              </div>
            ))}
          </div>
          <CustomPackageCard />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default PricingPlans;
