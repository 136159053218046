import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function UpcomingCoursesSection() {
  const { t } = useTranslation();

  const courses = [
    {
      id: 1,
      title: t("upcomingCoursesSection.courses.ethics.title"),
      description: t("upcomingCoursesSection.courses.ethics.description"),
      borderColor: "border-blue-500",
      icon: "⚖️",
      time: t("upcomingCoursesSection.courses.ethics.time"),
    },
    {
      id: 2,
      title: t("upcomingCoursesSection.courses.blogging.title"),
      description: t("upcomingCoursesSection.courses.blogging.description"),
      borderColor: "border-green-500",
      icon: "✍️",
      time: t("upcomingCoursesSection.courses.blogging.time"),
    },
    {
      id: 3,
      title: t("upcomingCoursesSection.courses.entrepreneurship.title"),
      description: t(
        "upcomingCoursesSection.courses.entrepreneurship.description"
      ),
      borderColor: "border-teal-500",
      icon: "💼",
      time: t("upcomingCoursesSection.courses.entrepreneurship.time"),
    },
  ];
  

  return (
    <div className="py-16 bg-gray-100">
      <div className="text-center mb-12">
        <h2 className="text-center text-2xl sm:text-3xl lg:text-4xl font-bold mb-8">
          {t("upcomingCoursesSection.title")}
        </h2>
        <p className="text-gray-700 mt-4">
          {t("upcomingCoursesSection.description")}
        </p>
      </div>

      <div className="container mx-auto md:px-0 px-5 flex flex-wrap justify-center gap-5">
        {courses.map((course, index) => (
          <a
            href={`/upcoming-course/${course.id}`}
            key={index}
            data-aos="fade-up"
            className={`border-4 ${course.borderColor} border-opacity-15 text-center hover:cursor-pointer transition-opacity duration-200 rounded-lg p-6 text-gray-800 bg-white flex flex-col items-center w-full sm:w-80`}
          >
            <div className="text-4xl mb-4">{course.icon}</div>
            <h3 className="text-xl font-bold mb-2">{course.title}</h3>
            <p className="text-base text-center mb-4">{course.description}</p>
            {/* <span className="text-sm text-gray-500 italic">{course.time}</span> */}
          </a>
        ))}
      </div>
    </div>
  );
}

export default UpcomingCoursesSection;
