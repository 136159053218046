import React from "react";
import { useTranslation } from "react-i18next";
import ListingsPage from "../components/ListingsPage";
import Navbar from "../components/Navbar";
import ClassListingHeroSection from "../components/class-details/ClassListingHeroSection";
import Footer from "../components/Footer";
import EnglishClass from "../assets/EnglishClass.webp";

import adv1 from "../assets/adv-1.svg";
import adv2 from "../assets/adv-2.svg";
import adv3 from "../assets/adv-3.svg";
import adv4 from "../assets/adv-4.svg";
import About from "../components/class-details/about";
import WhyChooseUs from "../components/class-details/why-choose-us";
import Pricing from "../components/class-details/pricing";
import Testimonials from "../components/class-details/testimonials";
import Instructor from "../components/class-details/instructor";
import Advantages from "../components/class-details/advantages";
import FAQ from "../components/class-details/FAQ";
import Consultations from "../components/class-details/consultation";
import myprofile from "./../assets/myProfilePicture.png";
import person from "../assets/EnglishClass.webp";

const MathClassListings: React.FC = () => {
  const { t } = useTranslation();

  // Define englishClasses array with translated properties
  const englishClasses = [
    {
      id: 1,
      title: t("mathClasses.listings.title"), // Translated title
      description: t("mathClasses.listings.description"), // Translated description
      ageRange: t("mathClasses.listings.ageRange"), // Translated age range
      imageUrl: EnglishClass,
      rating: "*****",
    },
    // Add more class items as needed
  ];

  // Tags for the hero section
  const tags = [
    t("tagsforart1", { ns: "classes" }), // Translated tag with namespace
    t("tagsforart2", { ns: "classes" }), // Example for dynamic age range
  ];

  // Optional filters
  // const filters = [
  //   t("filters.dayOrTime"),
  //   t("filters.date"),
  //   t("filters.classSize"),
  //   t("filters.anyPrice"),
  //   t("filters.age"),
  //   t("filters.more"),
  // ];

  const advantages = [
    {
      id: 1,
      name: t("advantages1", { ns: "classes" }),
      icon: adv1,
    },
    {
      id: 2,
      name: t("advantages2", { ns: "classes" }),
      icon: adv2,
    },
    {
      id: 3,
      name: t("advantages3", { ns: "classes" }),
      icon: adv3,
    },
    {
      id: 4,
      name: t("advantages4", { ns: "classes" }),
      icon: adv4,
    },
  ];

  const plans = [
    {
      title: "starter",
      price: "Free",
      features: ["Basic support", "5 projects", "Community access"],
    },
    {
      title: "pro",
      price: "$9.99/mo",
      features: ["Priority support", "Unlimited projects", "Advanced tools"],
    },
    {
      title: "plus",
      price: "$19.99/mo",
      features: [
        "Dedicated support",
        "Unlimited projects",
        "All tools included",
      ],
    },
  ];

  const testimonials = [
    {
      name: "Abror Tulaev",
      date: "24-September 2024 yil",
      text: "Assalomu Alaykum! Darstopdan juda minnatdorman. Darslar sifati hamda o'qituvchilarning dars o'tishi usullariga gap yo'q!",
      rating: 4,
      image: person,
    },
    {
      name: "Shohida Zinnatullayeva",
      date: "6 iyun 2023 yil",
      text: "Assalomu Alaykum! Ismim Shohida, Sirdaryo viloyatidanman...",
      rating: 3,
      image: person,
    },
    // Add more testimonials as needed
  ];

  const profile = {
    title: t("tutor.english.title", { ns: "classes" }),
    body: t("tutor.english.body", { ns: "classes" }),
    image: myprofile,
    advantages: t("tutor.english.advantages", {
      ns: "classes",
      returnObjects: true,
    }) as string[],
  };

  return (
    <>
      <Navbar />
      <ClassListingHeroSection
        title={t("mathClasses.title")}
        description={t("mathClasses.description")}
        reviews={t("mathClasses.reviews")}
        tags={tags} // Pass translated tags
        advantages={advantages}
        background={EnglishClass}
      />
      <About />
      <WhyChooseUs
        advantagesTitle={t("advantagesEnglishTitle", { ns: "classes" })}
      />
      {/* <Pricing plans={plans} /> */}
      <Testimonials testimonials={testimonials} />
      <Instructor profile={profile} />
      <Advantages />
      <FAQ />
      <Consultations />
      {/* <ListingsPage
        classes={quranClasses}
        // filters={filters} // Pass filters to ListingsPage
      /> */}
      <Footer />
    </>
  );
};

export default MathClassListings;
