import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSmile, FaRocket, FaClock } from "react-icons/fa";
import FreeTrialModal from "../FreeTrialModal";
import ContactDetailsModal from "../ContactDetailsModal";

export default function UpcomingSection() {
  const { t } = useTranslation();
  const [isFreeTrialModalOpen, setIsFreeTrialModalOpen] =
    useState<boolean>(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);

  const openFreeTrialModal = () => setIsFreeTrialModalOpen(true);
  const closeFreeTrialModal = () => setIsFreeTrialModalOpen(false);

  const openContactModal = () => {
    closeFreeTrialModal();
    setIsContactModalOpen(true);
  };

  const closeContactModal = () => setIsContactModalOpen(false);

  return (
    <div className="py-10 sm:py-20 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white rounded-lg shadow-lg">
      <div className="container mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-8 animate-bounce">
          🚀 {t("upcomingSection.title")} 🎉
        </h2>
        <div className="text-center p-6">
          <p className="text-lg mb-6 flex items-center justify-center gap-2">
            <FaClock className="text-2xl animate-spin" />{" "}
            {t("upcomingSection.description1")}
          </p>
          <p className="text-md mb-8 flex items-center justify-center gap-2">
            <FaSmile className="text-2xl animate-pulse" />{" "}
            {t("upcomingSection.description2")}
          </p>
          <button
            onClick={() => openContactModal()}
            className="bg-white text-purple-600 font-semibold py-2 px-6 rounded-full shadow-lg hover:bg-purple-600 hover:text-white transform hover:scale-110 transition-all duration-300"
          >
            {t("upcomingSection.notifyButton")}
          </button>
        </div>
      </div>

      {isFreeTrialModalOpen && (
        <FreeTrialModal
          closeModal={closeFreeTrialModal}
          openContactModal={openContactModal}
        />
      )}
      {isContactModalOpen && (
        <ContactDetailsModal closeModal={closeContactModal} />
      )}
    </div>
  );
}
